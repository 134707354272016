import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './LogoCarouselItem.scss';

import { customPropTypes } from '../../../util';

function LogoCarouselItem({ data: { icon, subtitle, title, date, link, bg_color, font_color } }) {
  const style = {};
  // Override the bg color of the card if there is a given value
  if (bg_color) {
    style.backgroundColor = bg_color;
  }
  if (font_color) {
    style.color = font_color;
  }

  return (
    <a style={style} href={link} target="_blank" rel="noopener noreferrer" className="LogoCarouselItem">
      <div className="icon-container">
        <img className="icon" src={icon.image.default.url} alt={icon.image.default.alt} />
      </div>
      <p className="subtitle">{subtitle}</p>
      <h5 className="title">{title}</h5>
      <p className="date">{date}</p>
    </a>
  );
}

LogoCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    icon: customPropTypes.image,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    link: PropTypes.string,
    bg_color: PropTypes.string,
  }).isRequired,
});

LogoCarouselItem.defaultProps = {};

export default memo(LogoCarouselItem);
